import { makeStyles } from '@material-ui/core/styles';
import { headerImage } from '../../../assets/images/newImages';
import {RiaPayCoverImage} from '../../../assets/images/newImages';

export const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 160,
    [theme.breakpoints.down('md')]: {
      "& img": {
        width: '50px'
      }
    },
  },
  rootCart: {
    display: 'flex',
    alignItems: 'center',
    // width: '100%',cC
    height: 100,
    background: '#FFFFFF',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  modalSuccess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundImage:`url(${RiaPayCoverImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  radioForm: {
    "&.MuiIconButton-root ": {
      position: 'absolute !important',
      right: '0px !important'
    }
  },
  paper: {
    // background: 'linear-gradient(0deg, rgba(0, 157, 224, 0.05) 0%, rgba(0, 157, 224, 0.05) 100%), #FFF',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: "0px", //14
    // borderRadius: '20px',
    boxSizing: 'border-box',
    width: '100%',//'350px',
    height: '100%',
  },
  paperSuccess: {
    background: '#FFF',//'linear-gradient(0deg, rgba(0, 157, 224, 0.05) 0%, rgba(0, 157, 224, 0.05) 100%), #FFF',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: "14px",
    borderRadius: '20px',
    boxSizing: 'border-box',
    // width: '100%',
    height: '100%',
    maxWidth: '540px',
    margin: '0px auto',
    width: '100%'
  },
  modalPay: {
    width: '100%',
    position: 'absolute !important',
    bottom: '0px !important',
    zIndex: '9999 !important',
    maxWidth: '540px',
    margin: '0px auto',
  },
  paperPay: {
    borderRadius: '20px 20px 0px 0px',
    background: '#FFF',
    position: 'fixed',//'absolute',
    bottom: '0px',
    width: '100%',
    maxWidth:'540px'
  },
  testingTab: {
    "&.MuiPaper-elevation4": {
      boxShadow: 'none !important'
    },
    '& .MuiTabs-scrollButtons': {
        width: 0,
        display: 'none',              // Hide the scroll buttons
      },
  },
  paymentSuccessEmailField:{
    "& .MuiOutlinedInput-root": {
      borderRadius: '15px !important',
      // marginBottom:'50px !important',
    },
    '& .MuiInputBase-input::placeholder': {
      color:'#748189',
      fontFamily:'Outfit',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
  },
  
}));